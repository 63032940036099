
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  props: {},
  components: {Breadcrumbs},
})
export default class PersonalFile extends Vue {
  dataNameArr:Array<string>=["workingTrace","creditBase","businessIndicators","termination","training","creditSpecial"];
  zzlApi: any;
  zzlFormat: any;
  get memberId() {
    return this.$route.query.memberId;
  }
  last_router: any = "";
  photos: Array<string> = [];
  basicInfo: any = []; // 个人基本信息
  workingTrace: any = []; // 从业轨迹信息
  training: Array<any> = []; //培训记录
  termination: Array<any> = []; //被解决信息
  businessIndicators: Array<any> = [];
  creditBase: Array<any> = [];
  creditSpecial: Array<any> = [];
  idArr: any = [];
  // 执业证状态
  licensedStatusOption: Array<any> = [
    {
      label: "有效",
      value: "F",
      //审核成功
    },
    {
      label: "审核中",
      value: "P",
      //审核中
    },
    {
      label: "无效",
      value: "E",
      //审核失败
    },
    {
      label: "已注销",
      value: "L",
      //失效
    },
  ];
  selectionChange(selectItems: any) {
    // this.idArr = selectItems.map((m: any) => {
    //   return m.id;
    // });
  }
  get keyVisible() {
    const loginData: any = window.sessionStorage.getItem("loginData");
    let role = JSON.parse(loginData).roles;
    let result: boolean = true;
    role.map((item: any) => {
      if (item === "municipal_company" || item === "province_company") {
        result = false;
      }
    });
    return result;
  }
  get navSource() {
    if (!this.last_router) return { txt: "" };
    return {
      txt: "个人信息查询",
      route: "InfoInquiry",
    };
  }
  previewPhotoAction(photo: any) {
    this.photos = [photo];
    this.$nextTick(() => {
      (this.$refs.viewer as any).$viewer.show();
    });
  }
  getDisplayText(value: any, type: any) {
    if (value == null || value == -2) return "";
    if (value == -1) return "无";
    if (value == 0 && type == 2) return "0%";
    if (type == 1) return value;
    return value + "%";
  }
  getBack() {
    window.sessionStorage.removeItem("personalFile_id");
    this.$router.push({ name: this.navSource.route }).catch(() => {});
  }
  //删除
  async deleteAction(row: any, type: any) {
    let ids: any = [];
    if (!row) {
      ids=(this.$refs[this.dataNameArr[type-1]] as any).selection.map((m:any)=>{ return m.id;})
      if (!ids.length) {
        this.$message.warning(`请至少选择一条数据`);
        return;
      }
    } else {
      ids = [row.id];
    }
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await this.zzlApi.home.SpecifyClear({ ids, type }, null);
        if (res.code == 0) {
          this.deleteRow(ids, type)
          this.$message.success("删除成功");
        } else {
          this.$message.error(`删除失败：${res.msg}`);
        }
      })
      .catch(() => {
        this.$message.info("已取消");
      });
  }
  deleteRow(ids:Array<number>,type:number){
    
    const dataName:string= this.dataNameArr[type-1];
    let dataArr:Array<any>=(this as any)[dataName];
    ids.forEach(id=>{
        let curIdx= dataArr.findIndex(f=>{
           return f.id==id;
         })
         dataArr.splice(curIdx,1)
    })
  }
  async getData() {
    const res = await this.zzlApi.member.GetArchives(
      { id: this.memberId },
      true
    );
    if (res.code === 0) {
      this.basicInfo = res.data.basicInfo;
      this.workingTrace = res.data.workingTrace;
      this.creditBase = res.data.creditBase;
      this.creditSpecial = res.data.creditSpecial;
      this.training = res.data.training;
      this.termination = res.data.termination;
      this.businessIndicators = res.data.businessIndicators;
    }
  }

  initAction() {
    this.last_router = window.sessionStorage.getItem("last_router");
  }

  async mounted() {
    this.initAction();
    this.getData();
  }
}
